let _configData = window._env_ || {};

const _env = "dev"; // stg, uat
const vendor = process.env.REACT_APP_CLIENT || "vd004";

export const setConfig = (config) => (_configData = config);

export const getConfig = () => _configData;

export const LocaleDash = {
  en_US: "en-us",
  /**
   * 印尼文
   */
  id_ID: "id-id",
  /**
   * 馬來西亞
   */
  ms_MY: "ms-my",
  /**
   * 越南
   */
  vi_VN: "vi-vn",
  zh_CN: "zh-cn",
  zh_HK: "zh-hk",
  ja_JP: "ja-jp",
  ko_KR: "ko-kr",
  /**
   * 泰文
   */
  th_TH: "th-th",
  /**
   * 印度文
   */
  hi_IN: "hi-in",
  /**
   * 葡萄牙文
   */
  pt_PT: "pt-pt",
  /**
   * 西文
   */
  es_ES: "es-es",
};


const APP_DOWNLOAD_MAP_FAKE = {
  webButton: false,
  android: {
    incoming: false,
    login: true,
    urls: [
      {
        appType: 8,
        url: "",
        packageType: 0,
        downloadLinks: [
          "https://s3.fumengwangluokeji.com/appdownload/android/sport_6686bet_202205041459_1.64.1_qat.apk",
        ],
      },
    ],
  },
  ios: {
    incoming: false,
    login: false,
    urls: [
      {
        appType: 16,
        url: "",
        packageType: "",
        downloadLinks: [
          "itms-services://?action=download-manifest&url=https://app1.36uqu13k.app/appdownload/ios/vd004Prod09191411V4.9.8B230919A2.plist",
        ],
        appPackageType: 11,
      },
      {
        appType: 8,
        url: "",
        packageType: 1,
        downloadLinks: [
          "itms-services://?action=download-manifest&url=https://app1.36uqu13k.app/appdownload/ios/vd004Prod09191411V4.9.8B230919A2.plist",
        ],
        appPackageType: 16,
      },
    ],
  },
};

const SYS_CONFIG_FAKE = {
  defaultCurrency: "CNY",
  currencies: [
    "CNY",
    "IDR",
    "MYR",
    "VND",
    "HKD",
    "THB",
    "KRW",
    "INR",
    "USD",
    "JPY",
    "BTC",
    "ETH",
    "LTC",
    "USDT_ERC20",
    "DOGE",
    "BCH",
    "DASH",
    "ETC",
    "USDT_TRC20",
    "AAVE_ERC20",
    "DAI_ERC20",
    "UNI_ERC20",
    "WBTC_ERC20",
    "USDC_ERC20",
    "TRX",
  ],
  defaultLocal: "zh-cn",
  locales: [
    "ms-my",
    "zh-cn",
    "vi-vn",
    "en-us",
    "id-id",
    "hi-in",
    "th-th",
    "zh-hk",
    "ko-kr",
    "ja-jp",
    "pt-pt",
  ],
  cryptoCurrencies: [
    "BTC",
    "ETH",
    "LTC",
    "USDT_ERC20",
    "DOGE",
    "BCH",
    "DASH",
    "ETC",
    "USDT_TRC20",
    "AAVE_ERC20",
    "DAI_ERC20",
    "UNI_ERC20",
    "WBTC_ERC20",
    "USDC_ERC20",
    "TRX",
  ],
};

const developmentConfigs = (env) => {
  switch (env) {
    case "dev":
    case "stg":
    case "uat":
      return {
        PLATFORM_URL: `https://en-${vendor}-universe-portal.inno${env}.site`,
        PLATFORM_API_URL: `https://tiger-api.inno${env}.site/platform`,
        FE_CDN_URL: "https://fe-source.dev.mppwr.com",
        DEPLOY_ENV: env,
        RELAY_URL: `https://en-${vendor}-tiger-relay.inno${env}.site`,
        APP_DOWNLOAD_MAP: APP_DOWNLOAD_MAP_FAKE,
        SYS_CONFIG: SYS_CONFIG_FAKE,
        SYSTEM: {
          lang: "zh_HK",
        },
        VENDERID: vendor,
      };
    default: {
    }
  }
};

if (!window._env_) setConfig(developmentConfigs(_env));
