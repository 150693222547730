import { getLocalStorage } from "utils/storage";
import LangList from "locales";
import { getConfig } from "config";

const API_LANG_KEY_TO_I18N = {
  "en-us": "en_US",
  "id-id": "id_ID",
  "ms-my": "ms_MY",
  "vi-vn": "vi_VN",
  "zh-cn": "zh_CN",
  "zh-hk": "zh_HK",
  "hi-in": "hi_IN",
  "ja-jp": "ja_JP",
  "ko-kr": "ko_KR",
  "th-th": "th_TH",
  "zh-tw": "zh_HK",
  "pt-pt": "pt_PT",
};

const navigatorLanguageToKey = (browserLang) => {
  const langMap = {
    en_US: ["en"],
    id_ID: ["id-id", "id"],
    ms_MY: ["ms-my", "ms"],
    vi_VN: ["vi-vn", "vi"],
    zh_CN: ["zh"],
    zh_HK: ["zh-hk", "zh-mo", "zh-tw"],
    hi_IN: ["hi-in", "hi"],
    ja_JP: ["ja-jp", "ja"],
    ko_KR: ["ko-kr", "ko"],
    th_TH: ["th-th", "th"],
    pt_PT: ["pt", "pt-PT", "pt-BR"],
  };
  browserLang = browserLang?.toLowerCase();
  if (!browserLang) return null;
  const exactMatching = (list, value) => list.includes(value);
  const fuzzyMatching = (list, value) =>
    list.some((val) => value.includes(val));
  for (const method of [exactMatching, fuzzyMatching]) {
    for (const [langKey, matchingList] of Object.entries(langMap)) {
      if (method(matchingList, browserLang)) return langKey;
    }
  }
  return "en_US";
};

const setLang = (lang) => {
  const data = { ...LangList[lang] };
  return data;
};

const config = getConfig()?.SYS_CONFIG;

let availableLocales = [
  "zh_CN",
  "en_US",
  "id_ID",
  "vi_VN",
  "ms_MY",
  "zh_HK",
  "hi_IN",
  "ja_JP",
  "ko_KR",
  "th_TH",
  "pt_PT",
];
if (config?.locales) {
  availableLocales = config.locales.map((item) => {
    return API_LANG_KEY_TO_I18N[item];
  });
}

let defaultLocale = "en_US";
if (config?.defaultLocal) {
  defaultLocale = API_LANG_KEY_TO_I18N[config.defaultLocal];
}

const initLang = () => {
  let lang = getLocalStorage("language");
  if (availableLocales.includes(lang)) return lang;
  lang = navigatorLanguageToKey(window?.navigator?.language);
  if (availableLocales.includes(lang)) return lang;
  lang = getConfig()?.SYSTEM?.lang;
  if (availableLocales.includes(lang)) return lang;
  return !availableLocales.includes(lang)
    ? availableLocales[0]
    : API_LANG_KEY_TO_I18N[defaultLocale];
};

const initState = {
  currentLang: initLang(),
  langData: setLang(initLang()),
  availableLocales,
  showLocalesSetting: false,
  merchantSetting: {},
  customerServiceUrls: []
};

const ReducerActions = (state = initState, action) => {
  switch (action.type) {
    case "SET_LANG":
      return {
        ...state,
        currentLang: action.payload,
        langData: setLang(action.payload),
      };
    case "SET_AVAILABLE_LOCALES":
      return {
        ...state,
        availableLocales: action.payload,
      };
    case "SET_SHOW_LOCALES_SETTING":
      return {
        ...state,
        showLocalesSetting: action.payload,
      };
    case "SET_CUSTOMER_SERVICE_URLS":
      return {
        ...state,
        customerServiceUrls: action.payload
      }
    case "SET_MERCHANT_SETTING":
      return {
        ...state,
        merchantSetting: action.payload
      }
    default:
      return state;
  }
};

export default ReducerActions;
